<template>
  <div class="container" style="padding-bottom: 72px">
    <div class="tabs-chunk">
      <p class="tabs-title">卖家签署人设置</p>

      <a-table :data-source="signdata" :columns="signColumns" :pagination="false" rowKey="key" :loading="loading"
        :row-class-name="rowClassName">
        <template v-for="col in ['signerName','signerIdCard','signerMobile']" :slot="col" slot-scope="text, record">
          <div :key="col">
            <a-input :style="{width: width(col)}" :maxLength="col | length" v-if="record.editable" :value="text"
              @change="e => handleChange(e.target.value, record.key, col)" />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="signStatus" slot-scope="text,record">
          <span v-if="record.isLegal"></span>
          <span v-else-if="text === 'UNSIGNED'" style="color: rgba(0, 0, 0, 0.25)">待签署</span>
          <span v-else-if="text === 'SIGNED'">已签署</span>
        </template>
        <template slot="isLegal" slot-scope="text">
          <a-icon v-if="text" type="check-circle" theme="filled" class="font-error" />
        </template>
        <template slot="isDefault" slot-scope="text">
          <a-icon v-if="text == 1" type="check-circle" theme="filled" class="font-error" />
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space v-if="record.editable">
            <a-icon type="check" @click="() => save(record)"></a-icon>
            <a-icon type="close" @click="() => cancel(record.key)"></a-icon>
          </a-space>
          <a-space v-else>
            <template v-if="!record.isDefault">
              <a @click="switchSigner(record)">切换签署人</a>
            </template>
            <!-- <template v-else-if="record.signStatus === 'SIGNED'">
              <a v-if="record.isDefault == 1" :href="record.agrtViewUrl" target="_Blank">代签署协议</a>
              <a v-else @click="switchSigner(record)">切换签署人</a>
            </template>
            <template v-else> -->
              <!-- <a-Popconfirm title="确定要重发短信？" @confirm="send(record.id)">
                <a>重发短信</a>
              </a-Popconfirm>
              <a :href="record.argtDocUrl" target="_Blank">签署链接</a> -->
            <!-- </template> -->
          </a-space>
        </template>
      </a-table>
      <div class="table-foot" @click="add" v-if="!signdata.find(item => item.key === -1)">
        <a-icon type="plus"></a-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { signtory, addsigntory, send, swSigner } from '@/api/seller'

const signColumns = [
  {
    title: '签署人姓名',
    dataIndex: 'signerName',
    scopedSlots: { customRender: 'signerName' }
  },
  {
    title: '身份证号',
    dataIndex: 'signerIdCard',
    scopedSlots: { customRender: 'signerIdCard' }
  },
  {
    title: '手机号',
    dataIndex: 'signerMobile',
    scopedSlots: { customRender: 'signerMobile' }
  },
  {
    title: '法人',
    dataIndex: 'isLegal',
    scopedSlots: { customRender: 'isLegal' }
  },
  {
    title: '默认',
    dataIndex: 'isDefault',
    scopedSlots: { customRender: 'isDefault' }
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' }
  },
]
const name = /^[\u4e00-\u9fa5]{2,4}$/
const phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
export default {
  props: ['id', 'name', 'individualApplyId'],
  data() {
    this.signCacheData = []
    return {
      loading: false,
      signColumns,
      signdata: [],
      editingKey: '',
    }
  },
  mounted() {
      this.signtory()
  },
  filters: {
    length(val) {
      if (!val) return ''
      const obj = {
        'signerName': 6,'signerIdCard': 18,'signerMobile': 11
      }
      return obj[val] || ''
    }
  },
  methods: {
    // 添加时input的宽度
    width(key) {
      const obj = {
        'signerName': '80px',
        'signerIdCard': '175px',
        'signerMobile': '120px',
      }
      return obj[key]
    },
    // 获取权限管理数据
    signtory() {
      this.loading = true
      signtory({ entityCharacter: 'S', entityId: this.id }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.signdata = res.data.signToryVoList
          this.signCacheData = [...this.signdata]
          this.signCacheData.forEach((item, index) => item.key = index)
        }
      })
    },
    handleChange(value, key, column) {
      const newData = [...this.signdata];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.signdata = newData;
      }
    },
    // 卖家签署人添加操作
    edit(key) {
      console.log(key)
      const newData = [...this.signdata];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.signdata = newData;
      }
    },
    add() {
      if (this.signdata.find(item => item.key === -1)) return
      const obj = {
        key: -1, isDefault: null, signStatus: null,
        signedTime: null, signerIdCard: "", signerMobile: "", signerName: ""
      }
      this.signdata.push(obj)
      this.signCacheData.push(obj)
      this.edit(obj.key)
    },
    save(record) {
      if (!this.id) {
        this.$message.error('缺少主体ID')
        return
      }
      let verify = false
      console.log(name.test(record.signerName));
      if (!name.test(record.signerName)) {
        this.$message.error('请填写正确的签属姓名')
      } else if (!(record.signerIdCard.length===18)) {
        this.$message.error('请填写正确的身份证号')
      } else if (!phone.test(record.signerMobile)) {
        this.$message.error('请填写正确的手机号')
      } else {
        verify = true
      }
      if (!verify) return
      this.loading = true
      console.log('???',record)
      addsigntory({ ...record, supplierId: this.id }).then((res) => {
        this.loading = false
        if (res.code === 200) {
          this.editingKey = ''
          this.$message.success(res.message)
          this.signtory()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    cancel(key) {
      const newData = [...this.signdata];
      this.signdata = newData.filter(item => key !== item.key);
      this.signCacheData = [...this.signdata]
      this.editingKey = '';
    },
    // 默认前输入颜色
    rowClassName(record) {
      return (record.isDefault === 1) ? 'check-color' : ''
    },
    send(signId) {
      send({supplierId: this.id, signId}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    switchSigner(record) {
      swSigner({supplierId: this.id, id: record.id, individualApplyId: this.individualApplyId}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.signtory()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .check-color {
  background: #e6f7ff;
}
/deep/ .ant-col {
  margin-bottom: 12px;
}
.container {
  border-top: 1px solid #e9e9e9;
  background: #fff;
  padding: 22px 32px;
  font-size: 14px;
  .tabs-title {
    font-size: 16px;
    font-weight: 650;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px;
  }
}
.tabs-chunk {
  margin-bottom: 32px;
  .table-foot {
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    user-select: none;
  }
  /deep/ .ant-table-placeholder {
    display: none;
  }
}
.add-modal {
  padding: 12px 32px;
}
.add-right {
  line-height: 32px;
  text-align: right;
}
.select-info {
  border-width: 1px;
  border-style: solid;
  border-color: #aaaaaa;
  background: #f2f2f2;
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 12px;
  /deep/ .ant-col {
    margin-bottom: 0;
  }
}
.font-error {
  color: @error;
  font-size: 16px;
}
</style>