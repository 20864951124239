<template>
  <div class="seller-info">
      <div class="info">
          <div class="info-header">
              <b>{{sellerInfo.name}}</b>
              <span>
                  <a-space>
                    <a-button type="primary" @click="$router.push(`/shop/s${$route.query.entityId}`)">查看店铺信息</a-button>
                    <!-- <a-button type="primary" @click="statusChange" v-if="sellerInfo.status === 'REGISTERED' || sellerInfo.status === 'UNREGISTERED'">{{sellerInfo.status | font}}</a-button> -->
                  </a-space>
              </span>
          </div>

          <a-row type="flex" justify="space-around" align="middle">
              <a-col :span="8">
                创建账号：{{sellerInfo.nickName}}
              </a-col>
              <a-col :span="8">
                企业编码：{{sellerInfo.code}}
              </a-col>
              <a-col :span="8">
                税务状态：{{sellerInfo.status | status}}
              </a-col>
              <a-col :span="8" class="group-col">
                关联集团：
                <a-tooltip placement="top">
                    <template slot="title">
                        <span>{{sellerInfo.mapList | group}}</span>
                    </template>
                    {{sellerInfo.mapList | group}}
                </a-tooltip>
              </a-col>
              <a-col :span="8">
                入驻时间：{{sellerInfo.createdTime}}
              </a-col>
              <a-col :span="8">
                成立时间：{{sellerInfo.licenseRegTime}}
              </a-col>
          </a-row>
      </div>

      <div class="tabs">
        <!-- tabs -->
        <a-tabs default-active-key="1" @change="tabsChange">
            <a-tab-pane key="1" tab="财务管理">
                <div class="container" style="padding-bottom: 72px">
                    <p class="tabs-title">发票管理</p>

                    <a-row :gutter="16">
                        <a-col :span="12" >发票抬头</a-col>
                        <a-col :span="12" >纳税人识别号</a-col>
                        <a-col :span="12" class="fs16">{{billInfo.supplierName}}</a-col>
                        <a-col :span="12" class="fs16">{{billInfo.unifiedCode}}</a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12"><span class="red">*</span> 发票银行名称</a-col>
                        <a-col :span="12"><span class="red">*</span> 发票银行账号</a-col>
                        <a-col :span="12"><a-input v-if="isEdit" v-model="billInfo.invoiceBankName" /><span v-else>{{billInfo.invoiceBankName}}</span></a-col>
                        <a-col :span="12">
                            <span><a-input v-if="isEdit" v-model="billInfo.invoiceBankAcc" @input="billInfo.invoiceBankAcc=$event.target.value.replace(/[^\d]/g,'')" /><span v-else>{{billInfo.invoiceBankAcc}}</span></span>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12"><span class="red">*</span> 发票电话</a-col>
                        <a-col :span="12"><span class="red">*</span> 发票地址</a-col>
                        <a-col :span="12"><a-input v-if="isEdit" :maxLength="18" v-model="billInfo.invoiceMobile" @input="billInfo.invoiceMobile=$event.target.value.replace(/[^\d|-]/g,'')"/><span v-else>{{billInfo.invoiceMobile}}</span></a-col>
                        <a-col :span="12"><a-input v-if="isEdit" v-model="billInfo.invoiceAddr" /><span v-else>{{billInfo.invoiceAddr}}</span></a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="24"><span class="red">*</span> 电子发票邮箱</a-col>
                        <a-col :span="12"><a-input v-if="isEdit" v-model="billInfo.invoiceEmail" /><span v-else>{{billInfo.invoiceEmail}}</span></a-col>
                        <a-col :span="12">
                            <a-button type="primary" v-if="!isEdit" @click="isEdit=true">修改</a-button>
                            <a-space v-if="isEdit">
                                <a-button type="primary"  @click="updateinvoice()">确定</a-button>
                                <a-button v-if="isEdit" @click="getsupplierinf();isEdit=false">取消</a-button>
                            </a-space>
                        </a-col>
                    </a-row>

                    <!-- <p class="tabs-title" style="margin-top: 42px">收款渠道</p>
                    
                    <a-row>
                      <a-col :span="24">网关收款银行卡：</a-col>
                    </a-row>

                    <a-row type="flex" :gutter="16">
                      <a-col :span="8" v-for="item in BankList" :key="item.bankAccountId">
                          <div class="bank-card">
                            <div class="icon">
                                <img :src="iconBank(item.bankName)" alt="">
                            </div>
                            <div class="select" :class="item.isDefault && 'select-check'" @click="defaultBank(item)">
                                <a-icon type="star" theme="filled" />
                            </div>
                            <p><b>{{item.bankName}}</b></p>
                            <p class="bank-code">{{item.openBankNo}}&nbsp;</p>
                            <p class="bank-num">{{item.bankAccount}}</p>
                          </div>
                      </a-col>

                      <a-col :span="8">
                          <div class="bank-bind" @click="addBank.off = true">
                            <a-icon type="plus" />
                          </div>
                      </a-col>
                    </a-row> -->

                    <!-- <div class="balance-pay">
                        <span>
                            余额支付：
                        </span>
                        <img src="../../assets/images/unit1.png" alt="">
                        <span class="balance">￥ 150,000.00</span>
                        <a-button type="danger">前往余额管理</a-button>
                    </div>

                    <div>
                        <span>支付密码：</span>
                        <span class="font-gray" style="margin-right: 48px">在账户资金变动、订单支付时，需要输入的支付密码</span>
                        <a @click="editPwd.off=true">重置</a>
                    </div> -->
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="权限管理">
                <RoleConfig ref="roleConfig" :id="sellerInfo.id" :name="sellerInfo.name"/>
            </a-tab-pane>
            <a-tab-pane key="3" tab="签署设置">
                <SingConfig ref="roleConfig" :id="sellerInfo.id" :name="sellerInfo.name" :individualApplyId="sellerInfo.individualApplyId"/>
            </a-tab-pane>
        </a-tabs>
      </div>

      <!-- 注销纳税人弹窗 -->
      <a-modal
        v-model="modalOff"
        title="确认提示"
        centered
        class="off-model"
        >
        <a-icon type="question-circle" class="fl"/>
        <div class="modal">
            <b>注销确认</b>
            <p>确定要将 胶州张三再生资源回收站 注销吗？</p>
            <p class="bottom-text">您目前还有 <span>1</span> 笔订单未完成，请前往 <a>订单管理</a> 确认</p>
        </div>
        <template slot="footer">
            <a-button key="back" @click="modalOff = false">
                取消
            </a-button>
            <a-button key="submit" type="primary" disabled>
                确定
            </a-button>
        </template>
      </a-modal>
      <!-- 添加银行卡 -->
      <a-modal v-model="addBank.off" title="添加银行卡" centered :width="920" :footer="null" class="add-bank" @cancel="addBank.off = false;bankRestart()">
           <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                 <a-form-item label="银行账号：" required>
                    <a-row :gutter="10" >
                        <a-col :span="14"><a-input v-model="bankInfo.bankAccount" @input="bankInfo.bankAccount=$event.target.value.replace(/[^\d]/g,'')" @blur="blur(bankInfo.bankAccount)" placeholder="请输入" /></a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label="开户行查询：">
                    <a-row :gutter="10" >
                        <a-col :span="6">
                            <a-select 
                            style="width: 170px"
                            v-model="addBank.bankCode"
                            placeholder="请选择银行"
                            @change="BankChange">
                                <a-select-option v-for="item in Bank" :key="item.key">
                                {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="8">
                            <a-input-group compact>
                            <a-cascader style="width: 100%" :options="options" v-model="cityBind" :field-names="{ value: 'key', label: 'label', children: 'children' }" @change="cascaderChange" placeholder="请选择" />
                            </a-input-group>
                        </a-col>
                        <a-col :span="10">
                            <SearchSelect
                                ref="SearchSelect"
                                :btnType="false"
                                placeholder="请输入开户行关键词查询"
                                class="SearchSelect"
                                :type="['branchNo','branchName']"
                                :list="addBank.bankList"
                                @search="search"
                                @select="openingBank"
                            ></SearchSelect>
                        </a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label="开户行名称：">
                    <a-row :gutter="10" >
                        <a-col :span="14"><a-input :value="bankInfo.openBankName" disabled placeholder="查询后自动输入" /></a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label="开户行行号：">
                    <a-row :gutter="10" >
                        <a-col :span="14"><a-input :value="bankInfo.openBankNo" disabled placeholder="查询后自动输入" /></a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label="预留手机号" required>
                    <a-row :gutter="10" >
                        <a-col :span="14"><a-input v-model="bankInfo.bankReservedMobile" :maxLength="11" @input="bankInfo.bankReservedMobile=$event.target.value.replace(/[^\d]/g,'')" placeholder="请输入" /></a-col>
                    </a-row>
                </a-form-item>
            </a-form>
            
            <a-space class="sub">
                <a-button type="primary" size="large" @click="sub">提交</a-button>
                <a-button @click="addBank.off = false;bankRestart()" size="large">取消</a-button>
            </a-space>
      </a-modal>
      <!-- 修改支付密码 -->
      <a-modal v-model="editPwd.off" title="修改支付密码" centered :footer="null" class="edit-pwd">
           <p class="l-flex-allcenter">当前管理员手机号码：15888155577</p>
           <p class="l-flex-allcenter">
               <uInput v-model="editPwd.code" :hasClear="false" max="6" placeholder="短信验证码">
                   <span :class="editPwd.switchCode && 'verify-code'" @click="sendMsg">{{editPwd.count === 60 ? '发送验证码' : `${editPwd.count}秒后重发`}}</span>
                    <template #icon>
                    <a-icon type="message" />
                    </template>
               </uInput>
            </p>
           <p class="l-flex-allcenter">
               <uInput
                type="password"
                max="6"
                :sign="true"
                v-model="editPwd.pwd"
                placeholder="设置设置6位数字支付密码"
                @clear="editPwd.pwd = $event">
                    <template #icon>
                        <a-icon type="lock" />
                    </template>
                </uInput>
           </p>
           <p class="l-flex-allcenter">
               <uInput
                type="password"
                max="6"
                :sign="true"
                v-model="editPwd.pwd"
                placeholder="确认新密码"
                @clear="editPwd.pwd = $event">
                    <template #icon>
                        <a-icon type="lock" />
                    </template>
                </uInput>
           </p>

           <p class="l-flex-allcenter">
               <a-button type="primary" @click="editPwd.off=false">确认修改</a-button>
           </p>


      </a-modal>
  </div>
</template>

<script>
import uInput from '@/components/uInput'
import SearchSelect from '@/components/SearchSelect'
import RoleConfig from './components/RoleConfig.vue'
import SingConfig from './components/SingConfig.vue'
import {subject} from '@/api/public'
import bank from '../../utils/banks'
import {getsupplierinf, updateinvoice,updatestatus,banksList,citysList,bindList,addBank,defaultBank} from '@/api/seller'
import {branch} from '@/api/public'
const regEmail = /.{1,}@.{1,}\..{1,}/
// import options from '@/utils/city'
// import bank from '@/utils/bank'
const type = {
    'UNREGISTERED': '未登记',
    'REGISTERED': '已登记',
    'REGISTERING': '登记中',
    'APPLIED': '申请中',
    'CANCELLED': '已注销',
    'CANCELAPPLIED': '申请注销',
}
const font = {
    'UNREGISTERED': '申请税务登记',
    'REGISTERED': '注销小规模纳税人',
}
export default {
    components: {
        uInput,
        RoleConfig,
        SingConfig,
        SearchSelect
    },
    filters: {
        status: (val)=>type[val],
        font: (val)=>font[val],
        group(val) {
            if (val && val.length) {
                let str = ''
                val.forEach((item,index) => {
                str+=item.groupName + ((index !== val.length -1 ? '，' : ''))
                })
                return str
            }
            return ''
        }
    },
    data() {
        return {
            options: [],
            modalOff: false,
            isEdit: false,
            addBank: {
                off: false,
                cityCode: undefined,
                bankCode: undefined,
                bankList: []
            },
            editPwd: {
                off: false,
                switchCode: false,
                count: 60
            },
            cityBind: [],
            BankList: [],
            Bank: [], //银行列表
            bankInfo: {
                zsBankCode: '',
                bankName: "",
                openBankName: '',
                openBankNo: "",
                bankReservedMobile: '',
                bankAccount: ''
            },
            cascaderValue: [], //  城市值
            openBank: "", //开户行查询
            sellerInfo: {},
            // 发票
            billInfo: {
                supplierName: '',
                unifiedCode: '',
                invoiceBankName: '',
                invoiceBankAcc: '',
                invoiceMobile: '',
                invoiceEmail: '',
                invoiceAddr: '',
            },
            bankIconList: [
                {name: '工商银行', imgUrl: require("../../assets/images/bank/bankGS.png")},
                {name: '光大银行', imgUrl: require("../../assets/images/bank/bankGD.png")},
                {name: '华夏银行', imgUrl: require("../../assets/images/bank/bankHX.png")},
                {name: '建设银行', imgUrl: require("../../assets/images/bank/bankJS.png")},
                {name: '交通银行', imgUrl: require("../../assets/images/bank/bankJT.png")},
                {name: '农业银行', imgUrl: require("../../assets/images/bank/bankNY.png")},
                {name: '平安银行', imgUrl: require("../../assets/images/bank/bankPA.png")},
                {name: '浦发银行', imgUrl: require("../../assets/images/bank/bankPF.png")},
                {name: '人民银行', imgUrl: require("../../assets/images/bank/bankRM.png")},
                {name: '兴业银行', imgUrl: require("../../assets/images/bank/bankXY.png")},
                {name: '招商银行', imgUrl: require("../../assets/images/bank/bankZS.png")},
                {name: '中信银行', imgUrl: require("../../assets/images/bank/bankZX.png")},
            ]
        }
    },
    mounted() {
        this.subject()
        this.getsupplierinf()
    },
    methods: {
      tabsChange(key) {
          if (key == 1) {
            this.isEdit = false
            this.getsupplierinf()
          }
      },
      iconBank(val) {
        let url = require("../../assets/images/bank/all.png")
        this.bankIconList.forEach(item => {
            if (val.indexOf(item.name) !== -1) {
                url = item.imgUrl
            }
        })
        return url
      },
      search(e) {
          branch({keywords:e || undefined,cityCode: this.addBank.cityCode,bankCode: this.addBank.bankCode}).then(res => {
              this.addBank.bankList = res.data.branchList
          })
      },
      blur(e) {
          console.log(bank(e).bankName);
        if (bank(e).bankName) {
            this.Bank.forEach((item) => {
                if (item.label === bank(e).bankName) {
                    this.addBank.bankCode = item.key
                    this.bankInfo.bankName = item.label
                    this.bankInfo.zsBankCode = item.key
                }
            })
        } else {
            this.openBank = undefined
        }
      },
      openingBank(e) {
        console.log(e)
        this.bankInfo.openBankName = e.branchName
        this.bankInfo.openBankNo = e.branchNo
        this.Bank.find(item => item.branchNo === e.branchNo)
      },
      cascaderChange(e) {
        this.addBank.cityCode = e[1]
      },
      subject() {
          console.log(this.$route.query.entityId)
          subject('S', this.$route.query.entityId).then(res => {
            console.log(res)
            if (res.code === 200) {
                this.sellerInfo = res.data
            }
        })
        banksList().then(res=>{
           this.Bank=res.data
        })
        citysList().then(res => {
            this.options = res.data
        })
        this.bindList()
      },
      bindList() {
        bindList(this.$route.query.entityId).then(res => {
            this.BankList = res.data
        })
      },
      //  发票管理
      getsupplierinf() {
          getsupplierinf({entityCharacter: 'S', id: this.$route.query.entityId}).then(res => {
              console.log(res);
            if (res.code === 200) {
                this.billInfo =  {
                    supplierName: '',
                    unifiedCode: '',
                    invoiceBankName: '',
                    invoiceBankAcc: '',
                    invoiceMobile: '',
                    invoiceEmail: '',
                    invoiceAddr: '',
                    ...res.data
                }
            }
        })
      },
       BankChange(openBank) {
        this.addBank.bankCode = openBank
        this.bankInfo.bankName = this.Bank.find(item => item.key === openBank).label
        this.bankInfo.zsBankCode = openBank
        this.cityBind = []
        this.bankInfo.openBankName = ''
        this.bankInfo.openBankNo = ''
        this.$refs.SearchSelect.restart()
      },
      //  修改开票信息
      updateinvoice() {
        const myreg =/(^\d{2,4}-\d{7,8}$)|(^\d{2,4}\d{7,8}$)|(^\d{11}$)/
       if(!this.billInfo.invoiceBankName){
        this.$message.error('发票银行名称不能为空')
      }else if(!this.billInfo.invoiceBankAcc){
        this.$message.error('发票银行账户不能为空')
      }else if(!myreg.test(this.billInfo.invoiceMobile)){
        this.$message.error('请填写正确的发票电话')
      }else if(!regEmail.test(this.billInfo.invoiceEmail)){
        this.$message.error('请输入正确的电子发票邮箱')
      }else if(!this.billInfo.invoiceAddr){
        this.$message.error('发票地址不能为空')
      }else
         return updateinvoice({entityCharacter: 'S', id: this.$route.query.entityId, ...this.billInfo}).then(res => {
            if (res.code === 200) {
                console.log(res)
                this.isEdit = false
                this.getsupplierinf()
                this.$message.success(res.message)
            } else {
                this.$message.error(res.message)
            }
        })
      },
        // 获取验证码
      sendMsg() {
        this.editPwd.switchCode = true
        this.countDown()
      },
      countDown() {
        if (this.editPwd.count > 0) {
          this.editPwd.count--
          setTimeout(this.countDown, 1000)
        } else {
          this.editPwd.switchCode = false
          this.editPwd.count = 60
        }
      },
     // 修改状态
     statusChange() {
         const taxRegStatus = this.sellerInfo.status === 'UNREGISTERED' ? 'REGISTERING' : 'CANCELAPPLIED' 
         if (taxRegStatus === 'CANCELAPPLIED') {
             this.$confirm({
                title: '确定要注销吗？',
                centered: true,
                onOk:()=> {
                    updatestatus({id: this.$route.query.entityId,taxRegStatus }).then(res => {
                        if (res.code === 200) {
                            this.subject()
                            this.$message.success(res.message)
                        }
                    })
                }
            });
         } else {
            updatestatus({id: this.$route.query.entityId,taxRegStatus }).then(res => {
                if (res.code === 200) {
                    this.subject()
                    this.$message.success(res.message)
                }
            })
         }
     },
    //  添加银行卡
     sub() {
         console.log(this.bankInfo);
         const myreg =/^[1][3-9][0-9]{9}$/
         if (!this.bankInfo.bankAccount) {
            this.$message.error('请输入正确的银行账号')
            return
         } else if (!myreg.test(this.bankInfo.bankReservedMobile)) {
            this.$message.error('请输入正确的手机号')
            return
         }
         addBank({...this.bankInfo, supplierId: this.$route.query.entityId}).then(res => {
            if (res.code === 200) {
                this.$message.success(res.message)
                this.bankRestart()
                this.bindList()
                this.addBank.off = false
            } else {
                this.$message.error(res.message)
            }
         })
     },
    //  设置默认银行卡
     defaultBank(item) {
        if (item.isDefault) return
        item.isDefault = 1
        defaultBank({supplierId: this.$route.query.entityId, bankAccountId: item.bankAccountId}).then(res => {
            if (res.code === 200) {
                this.$message.success(res.message)
                this.bindList()
            } else {
                this.$message.error(res.message)
                item.isDefault = 0
            }
        })
     },
     bankRestart() {
        this.bankInfo = {
            bankName: "",
            openBankName: '',
            openBankNo: "",
            bankReservedMobile: '',
            bankAccount: ''
        }
        this.addBank.bankCode = undefined
        this.addBank.cityCode = undefined
        this.cityBind = []
        this.$refs.SearchSelect.restart()
     }
    }
}
</script>

<style lang="less" scoped>
.seller-info {
    width: 100%;
    .info {
        padding: 20px;
        background: #fff;
        .info-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            b {
                font-size: 20px;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
    .tabs {
        .font-gray { color: rgba(0, 0, 0, 0.25);}
        .container {
            background: #fff;
            padding: 22px 32px;
            font-size: 14px;
            border-top: 1px solid #e9e9e9;
            .tabs-title {
                font-size: 16px;
                font-weight: 650;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 12px;
            }
            /deep/ .ant-row {
                margin-bottom: 16px;
            }
            .bank-card {
                height: 111px;
                background-color: #5bb0ff;
                border-radius: 5px;
                padding: 16px 0;
                padding-left: 54px;
                position: relative;
                color: #fff;
                .bank-code {
                    font-size: 12px;
                    margin-bottom: 16px;
                }
                .bank-num {
                    font-size: 16px;
                }
                .icon {
                    position: absolute;
                    left: 0;
                    width: 42px;
                    height: 42px;
                    background: #bfeef7;
                    // border: 1px solid rgba(0, 0, 0, 0.8);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 8px;
                    img {
                        width: 42px;
                        height: 42px;
                    }
                }
                .select {
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    i {
                        cursor: pointer;
                        transition: all .3s;
                        &:hover {
                            color: @error;
                        }
                    }
                }
                .select-check {
                    color: @error;
                }
            }
            .bank-bind {
                min-height: 111px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #e1e1e1;
                border-radius: 5px;
                height: 100%;
                color: #fff;
                cursor: pointer;
                font-size: 32px;
            }
            .balance-pay {
                margin: 20px 0;
                img {
                    width: 160px;
                    border: 1px solid #aaaaaa;
                    border-radius: 10px;
                }
                .balance {
                    font-size: 36px;
                    color: @error;
                    margin: 16px;
                }
            }
        }
    }
}
.red{
    color: red;
}
.fs16{
    color: #000;
}
.off-model {
    .fl {
        font-size: 32px;
        color: #FE9400;
    }
    .modal {
        margin-left: 42px;
        b {
            font-size: 16px;
            color: rgb(102, 102, 102);
        }
        p {
            color: #999;
        }
        .bottom-text {
            span {
                font-size: 20px;
                color: #b8741a;
            }
        }
    }
    /deep/ .ant-modal-body {
        padding: 8px 24px 24px 24px;
    }
    /deep/ .ant-modal-header {
        border-bottom: none;
    }
}
.add-bank {
    .sub {
        width: 100%;
        justify-content: center;
        margin-bottom: 24px;
    }
    /deep/ .ant-form-item {
        margin-bottom: 0;
    }
}
.edit-pwd {
    .l-flex-allcenter {
        margin-bottom: 20px;
        span {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            color: @primary;
        }
        .verify-code {
            color: #cccccc;
        }
        &:nth-child(1) {
            font-size: 16px;
        }
    }
}
/deep/ .ant-col {
    font-size: 14px;
    margin-bottom: 12px;
}
.group-col {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/deep/ .ant-tabs-nav-scroll {
    padding: 0 20px;
    background: #fff;
}
/deep/ .ant-tabs-bar {
    margin: 0;
}
</style>